<template>
  <div class="user-content">
    <div class="scrollbar">
      <div class="bold">
        <h2>我的订单</h2>
      </div>
      <div class=" bg-white" v-if="!paymentVisible">
        <!--          搜索-->
        <div class="wrapper">
          <!--        搜索-->
          <div class="screening-bar">
            <ul>
              <li>
                <span class="screening-title">筛选条件：</span>
                <div class="screening-list search">
                  <div class="time-search">
                    <el-select class="timeType" v-model="searchData.timeType" placeholder="请选择">
                      <el-option v-for="item in timeSearchType" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                    <el-date-picker
                        @change="timeSearch"
                        v-model="searchTime"
                        type="daterange"
                        range-separator="至"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                    <span class="screening-type" v-for="(item,index) in dateRangeList" :key="index" @click="getDateRange(item.intervalDays,index)" :class="{ active: rangeActive===index }">{{ item.name }}</span>
                  </div>
                  <div>
                    <el-select class="sType" v-model="searchData.SType" placeholder="请选择">
                      <el-option v-for="item in searchType" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                    <el-input class="sType" v-if="searchData.SType==='订单号'" v-model="searchData.orderId" placeholder="请输入订单号" @change="search"/>
                    <el-input class="sType" v-model="searchData.goodsName" placeholder="请输入商品名称" @change="search"/>
                  </div>
                </div>
              </li>
              <li v-for="(item, index) in dataes" :key="index">
                <span class="screening-title">{{ item.name }}:</span>
                <div class="screening-list">
                  <span class="screening-type" @click="addClassFun(index, item.name, -1)" :class="{ active: item.name + '_-1' == classType(index) }">{{ item.type }}</span>
                  <span class="screening-span" v-for="(items, i) in item.list" :key="item.name + '_' + i" @click="addClassFun(index, item.name, i,items)" :class="{ active: item.name + '_' + i == classType(index) }">{{ items }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--列表-->
        <div class="table">
          <div class="header">
            <span class="header-item detail">订单详情</span>
            <span class="header-item">买家</span>
            <span class="header-item">金额</span>
            <span class="header-item">交易状态</span>
            <span class="header-item">支付状态</span>
            <span class="header-item control">操作</span>
          </div>

          <div class="content-wrapper" v-if="tableData.length>0">
            <div class="content" v-for="(item,index) in tableData" :key="index">
              <div class="order-detail">
                <span class="table-item-header">{{ toDate(item.createTime,'yyyy-MM-dd HH:mm:ss') }}</span>
                <span class="table-item-header">订单号：<span class="is-bold">{{ item.orderNo }}</span></span>
                <span class="table-item-header">{{ item.tenantName }}</span>
              </div>
              <div class="children-wrapper">
                <div class="children-content" ref="detail">
                  <div class="content-item children-detail" :style="{'min-height':controlHeight[index]}">
                    <div class="detail-wrapper" v-for="(children,index) in item.goodsList" :key="index">
                      <img :src="loadUrl(children.goodsImg)" alt="" />
                      <span class="title">{{ children.goodsTitle }}</span>
                      <span class="price">￥{{ children.goodsPrice }}</span>
                      <span class="num">x{{ children.goodsNum }}</span>
                    </div>

                  </div>
                  <div class="content-item" :style="{height:detailHeight[index]}">{{ item.buyerUserName }}</div>
                  <div class="content-item total-price" :style="{height:detailHeight[index]}">
                    <span>￥{{ item.orderPrice }}</span>
                    <span class="payChannel" v-if="item.payChannel && item.payStatus==2">{{ (item.payChannel === 'wx'||item.payChannel === '1') ? '微信支付' : (item.payChannel === 'alipay'||item.payChannel === '2') ? '支付宝支付' : '' }}</span>
                  </div>
                  <div class="content-item grey" :style="{height:detailHeight[index]}">
                    {{item.orderStatus==1?'进行中':item.orderStatus==2?'已完成':item.orderStatus==3?'已取消':item.orderStatus==4?'已结算':''}}
                  </div>
                  <div class="content-item grey" :style="{height:detailHeight[index]}" :class="{orange:item.payStatus == 1}">
                    <el-tag v-if="item.payStatus==1">待支付</el-tag>
                    <el-tag v-else-if="item.payStatus==2" type="success">已支付</el-tag>
                    <el-tag v-else-if="item.payStatus==3" type="danger">已取消</el-tag>
                    <el-tag v-else-if="item.payStatus==4" type="warning">已退款</el-tag>
                  </div>

                  <div class="content-item btn" :style="{'min-height':detailHeight[index]}" ref="control">
                    <div class="count-down danger" v-if="item.countDownTime !=='倒计时结束' && item.orderStatus==1">
                      {{ item.countDownTime }}
                    </div>
                    <el-button size="small" v-if="item.orderStatus==1" @click="pay(item.orderNo)" class="blue-btn">付款</el-button>
                    <el-button size="small" @click="checkDetail(item.orderNo)">查看详情</el-button>
                    <el-button size="small" v-if="item.orderStatus==1" @click="cancelPayment(item.orderNo)">取消订单</el-button>
                    <el-button size="small" v-else @click="deletePayment(item.orderNo)">删除</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="empty" v-else>暂无数据</div>

          <el-pagination
              class="page-bar"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchData.current"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="searchData.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>


        </div>
      </div>
<!--      <pay :orderNumber="orderNumber" @close="closePayment" :offline="true" type="payment" v-else/>-->
      <Pay v-if="payVisible" ref="Pay"/>
    </div>


  </div>
</template>

<script>
import {getOrderList, delMemberList, cancelMemberList} from '@/api/order'
// import pay from '../payment/index'
import Pay from "@/pages/payment/pay.vue";

export default {
  components: {Pay},
  data() {
    return {
      payVisible:false,
      paymentVisible: false,
      listLoading: false,
      detailHeight: [],
      controlHeight: [],
      searchData: {
        timeType: '下单时间',
        startDate: '',
        endDate: '',
        SType: '订单号',
        orderId: '',
        goodsName: '',
        goodsType: '',
        orderType: '',
        payStatus: '',
        payChannel: '',
        queryType: 1,
        current: 1,
        size: 10
      },
      total: 0,
      searchTime: [],
      timeSearchType: ['下单时间'],
      searchType: ['订单号'],
      dateRangeList: [
        {name: '今日', intervalDays: 0},
        {name: '昨日', intervalDays: 1},
        {name: '近七天', intervalDays: 7},
        {name: '近三十天', intervalDays: 30},
        {name: '近一年', intervalDays: 365}
      ],
      rangeActive: null,
      dataes: [
        {
          name: '商品类型',
          type: '不限',
          list: [
            '赛事报名',
            '会员申请',
            '培训报名',
            '活动报名',
            '教学活动',
            '复核考试'
          ],
          isShow: true
        },
        {
          name: '订单类型',
          type: '不限',
          list: [
            '普通订单',
            '非会员优惠金额',
            '非会员折扣',
            '会员折扣',
            '会员优惠金额'
          ],
          isShow: true
        },
        {
          name: '订单状态',
          type: '不限',
          list: [
            '未付款',
            '已付款'
          ],
          isShow: true
        },
        {
          name: '支付方式',
          type: '不限',
          list: ['微信支付', '支付宝支付'],
          isShow: true
        }
      ],
      classType0: '商品类型_-1',
      classType1: '订单类型_-1',
      classType2: '订单状态_-1',
      classType3: '支付方式_-1',
      dataList: [],
      tableData: [],
      orderNumber: ''
    }
  },

  mounted() {
    this.search()
    window.addEventListener('resize', this.getHeight)
  },

  methods: {
    getHeight() {
      this.detailHeight = []
      this.controlHeight = []
      this.$nextTick(() => {
        if (this.$refs['detail']) {
          this.$refs['detail'].map((item) => {
            if(item.offsetHeight == 120){
              this.detailHeight.push(item.offsetHeight - 20 + 'px')
            }else {
              this.detailHeight.push(item.offsetHeight + 'px')
            }
          })
        }
        if (this.$refs['control']) {
          this.$refs['control'].map((item) => {
            if(item.offsetHeight == 120){
              this.controlHeight.push(item.offsetHeight - 20 + 'px')
            }else {
              this.controlHeight.push(item.offsetHeight + 'px')
            }
          })
        }
      })
    }
    ,
    timeSearch() {
      if(this.searchTime){
        this.searchData.startDate = this.searchTime[0]
        this.searchData.endDate = this.searchTime[1]
      }else {
        this.searchData.startDate = ''
        this.searchData.endDate = ''
      }
      this.search()
    },
    search() {
      // let user = JSON.parse(window.localStorage.getItem('user'))
      // if (user.userInfo.tenant_id) {
      //   this.searchData.queryType = 3
      // }
      this.$store.commit("showLoading");
      getOrderList(this.searchData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records
          this.total = res.data.total
          this.tableData.map((item, index) => {
            item.payTime = this.toDate(item.payTime)
            this.$set(item, 'countDownTime', '')
            if(item.createTime){
              this.countDown(index)
            }
          })
          this.getHeight()
        }
        this.$store.commit("hideLoading");
      })
    },
    //倒计时
    countDownFun(time) {
      let startTime = new Date() //当前时间
      let end = new Date(time) //结束时间

      // console.log(end)
      let result = parseInt((end - startTime + 1800000) / 1000) //计算出豪秒

      let m = parseInt((result / 60) % 10) //剩余分钟就是用1小时等于60分钟进行趣余
      let s = parseInt(result % 60)
      //当倒计时结束时，改变内容
      if (m <= 0) {
        return '倒计时结束'
      }
      if (s < 10) {
        s = '0' + s
      }
      if (m == 0) {
        return '剩余' + s + '秒'
      } else {
        return '剩余' + m + '分' + s + '秒'
      }

    },
    //定时器
    // 页面多个倒计时 归零时清除
    countDown(index) {
      let self = this
      let item = self.tableData[index]
      self.tableData[index].countDownFn = setInterval(() => {
        if (self.countDownFun(item.countDownTime) === '倒计时结束') {
          clearInterval(self.tableData[index].countDownFn)
        } else {
          item.countDownTime = self.countDownFun(item.createTime)
        }
      }, 1000)
    },

    getDateRange(intervalDays, index) {
      let oneDay = 24 * 60 * 60 * 1000
      let dateNow = new Date()
      this.searchData.startDate = this.formatDate(new Date(dateNow.getTime() - intervalDays * oneDay))
      this.searchData.endDate = this.formatDate(dateNow)
      this.rangeActive = index
      this.search()
    }
    ,
    formatDate(time) {
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let day = time.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }
    ,
    addClassFun(index, name, i, items) {
      switch (index) {
        case 0:
          this.classType0 = name + '_' + i
          if (i == -1) {
            this.searchData.goodsType = ''
          } else {
            this.searchData.goodsType = items === '赛事报名' ? 1001 : items === '会员申请' ? 1004 : items === '培训报名' ? 1002 : items === '活动报名' ? 1003: items === '教学活动' ? 1005 :items === '复核考试' ? 1011 : ''
          }

          this.search()
          break
        case 1:
          this.classType1 = name + '_' + i
          if (i == -1) {
            this.searchData.orderType = ''
          } else {
            this.searchData.orderType = items === '普通订单' ? 0 : items === '非会员优惠金额' ? 1 : items === '非会员折扣' ? 2 : items === '会员折扣' ? 3 : 4
          }
          this.search()
          break
        case 2:
          this.classType2 = name + '_' + i
          if (i == -1) {
            this.searchData.payStatus = ''
          } else {
            this.searchData.payStatus = items === '未付款' ? 1 : 2
          }
          this.search()
          break
        case 3:
          this.classType3 = name + '_' + i
          if (i == -1) {
            this.searchData.payChannel = ''
          } else {
            this.searchData.payChannel = items === '微信支付' ? '1' : '2'
          }
          this.search()
          break
      }
    }
    ,
    classType(index) {
      switch (index) {
        case 0:
          return this.classType0
          break
        case 1:
          return this.classType1
          break
        case 2:
          return this.classType2
          break
        case 3:
          return this.classType3
          break
      }
    }
    ,
    checkDetail(orderNumber) {
      this.$router.push({
        path: '/order-detail',
        query: {
          orderNumber
        }
      })

    }
    ,
    deletePayment(orderNumber) {
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMemberList(orderNumber).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          }
          this.search()
        })
      }).catch(()=>{})

    },
    cancelPayment(orderNumber) {
      cancelMemberList(orderNumber).then((res) => {
        if (res.code === 200) {
          setTimeout(() => {
            this.$message({
              type: 'success',
              message: '取消订单成功'
            })
            this.search()
          }, 1000)
        }

      })
    },
    // 分页
    handleSizeChange(val) {
      this.searchData.size = val
    },
    handleCurrentChange(val) {
      this.searchData.current = val
      this.search()
    },
    //支付
    pay(orderNumber) {
      // this.paymentVisible = true
      // this.orderNumber = orderNumber
      this.payVisible = true
      this.$nextTick(() => {
        this.$refs.Pay.init(orderNumber, 2);
      });
    },
    closePayment() {
      this.paymentVisible = false
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-content {
  //border: 20px solid #f0f0f0;
  //border-bottom-width: 0;
  //background: #fff;
  //flex: 1;

  .bold {
    height: 54px;
    margin-right: 20px;

    h2 {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.bg-white {
  //background-color: #fff;
  overflow-y: auto;

  .table {

    padding: 20px 20px 20px 0;

    .header {
      margin-bottom: 20px;
    }

    .order-detail {
      //width: 100%;
      height: 44px;
      background: #F8F8F8;
      list-style: none;
      display: flex;
      color: #999999;
      align-items: center;
      font-size: 14px;
      padding-left: 20px;


      .table-item-header {
        padding-right: 30px;
      }

      .is-bold {
        color: #333;
      }
    }


    .header,
    .children-header {
      display: flex;
      align-items: center;
      height: 44px;
      background: #F8F8F8;
      width: 100%;
      color: #555555;
      font-size: 14px;

      .header-item {
        //width: 150px;
        text-align: center;
        width: 10%;

        &.detail {
          //width: 500px;
          //flex-grow: 3;
          width: 45%;
        }

        &.control {
          width: 15%;
          //width: 200px;
          //flex-grow: 1;
        }
      }
    }

    .content {
      border: 1px solid #E9ECEE;
      margin-bottom: 20px;


      .children-content {
        display: flex;
        align-items: start;
        width: 100%;
        color: #555555;
        font-size: 14px;


        .content-item {
          //width: 200px;
          text-align: center;
          border-right: 1px solid #E9ECEE;
          padding-top: 20px;
          width: 10%;
          //box-sizing: border-box;

          &.grey {
            color: #aaaaaa;
          }

          &.orange {
            color: #FF8740;
          }

          &.children-detail {
            //width: 500px;
            //flex-grow: 3;
            width: 45%;

            div:first-child {
              border-top: none;
              //padding: 0 0 26px 20px;
              padding-top: 0;
            }

            .detail-wrapper {
              display: flex;
              padding-top: 20px;
              padding-left: 20px;
              padding-bottom: 20px;
              border-top: 1px solid #E9ECEE;
              //border-right: 1px solid #E9ECEE;
              img{
                width: 80px;
                height: 80px;
                object-fit: cover;
                margin-right: 20px;
                flex: 1;
              }
              span {
                text-align: start;
              }

              .title {
                flex: 4;
              }

              .price {
                text-align: center;
                color: #aaaaaa;
                flex: 1;

              }

              .num {
                text-align: center;
                color: #aaaaaa;
                flex: 1;

              }
            }


          }

          &.btn {
            //width: 200px;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            border-right: none;

            ::v-deep .el-button {
              width: 90px;
              margin: 0 0 10px 0;
            }

            .count-down {
              font-size: 13px;
              margin-bottom: 10px;
            }

            .blue-btn {
              color: #3F6ABA;
              border: 1px solid #1E50AE;
            }
          }

          &.total-price {
            display: flex;
            flex-direction: column;

            .payChannel {
              padding-top: 18px;
              color: #aaaaaa;
            }
          }


        }
      }

    }

    .empty {
      text-align: center;
    }

  }


}


.wrapper {
  //width: 1326px;
  margin-right: 20px;
  padding: 20px;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0;
  margin-top: 30px;
  border: 1px solid #E6E6E6;

  .time-search {
    width: 100%;
  }
}

.screening-bar {
  //padding: 15px;
  //border: 1px solid #e6e6e6;
  //border-radius: 4px;
  //margin-bottom: 12px;

  li {
    display: flex;
    justify-content: space-between;
    //align-items: top;
    // padding: 10px 0;
    font-size: 14px;
    line-height: 20px;
    color: #555;
  }

  .screening-title {
    margin: 10px 5px;
    font-size: 14px;
    color: #555;
    flex-shrink: 0;
  }
}

.screening-type,
.screening-list .screening-span {
  display: inline-block;
  cursor: pointer;
  margin: 10px 5px;
  padding: 0 10px;
  color: #555;
  border-radius: 3px;
}

.screening-type,
.screening-more {
  flex-shrink: 0;
  height: 20px;
}

.screening-list {
  flex-grow: 1;
  height: 40px;
  overflow: hidden;

  &.search {
    display: flex;
    flex-wrap: wrap;
    height: 100px;

    .timeType {
      width: 138px;
      padding-right: 9px;

    }

    .sType {
      width: 230px;
      padding-right: 9px;
    }
  }
}

.screening-more {
  margin-top: 10px;
  color: #999;
}

.screening-bar li .screening-type:hover,
.screening-bar li .screening-type.active,
.screening-bar li .screening-span:hover,
.screening-bar li .screening-span.active {
  background-color: #1e50ae;
  color: #fff;
}

::v-deep.page-bar {
  width: 99%;
}

.scrollbar {
  overflow-y: scroll;
}
</style>
